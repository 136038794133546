<template>
	<div>
		<div class="js-copy js-content pd_body" @scroll="handleScroll">
			<headerNav :isScrolled="isScrolled" :currentPath="currentPath">
			</headerNav>
			<div class="pd_banner">
				<div class="pd_banner_img">
					<img
						class="pd_banner_img_1"
						src="https://zy.metaera.media/assets/images/event/45.png"
						alt=""
					/>
					<img
						class="pd_banner_img_2"
						src="https://zy.metaera.media/assets/images/event/46.png"
						alt=""
					/>
					<div class="pd_banner_img_overlay"></div>
				</div>
				<div class="pd_banner_30">BECOME A SPONSOR</div>
				<div class="pd_banner_31"
					>Polkadot Decoded Asia is the premier event in the Polkadot ecosystem,
					bringing together developers, investors, enthusiasts, and industry
					leaders to explore the latest developments in blockchain technology.
					So put your brand on their radars and give it the exposure it needs.
					<br /><br />​ If you want to be a sponsor, please email us at
					<span @click="sendEmail()">event@metaera.hk</span>.
				</div>
				<div class="pd_banner_32 pointer" @click="sendEmail()">
					<img
						:src="currentImage"
						alt=""
						@mouseover="changeImage('b')"
						@mouseleave="changeImage('a')"
					/>
				</div>
			</div>
			<div class="pd_partner">
				<div class="pd_partner_line"> </div>
				<div class="pd_partner_line_red">
					<div class="pd_partner_line_red_sub"> </div>
				</div>
				<div class="pd_partner_title"> WHY SPONSOR?  </div>

				<div class="pd_partner_content">
					<div class="pd_partner_img">
						<div class="pd_partner_sub_img">
							<img
								src="https://zy.metaera.media/assets/images/event/20.png"
								alt=""
							/>
						</div>
						<div class="pd_partner_sub_num">Brand Exposure</div>
						<div class="pd_partner_sub_name"
							>Your brand is prominently featured in all promotional
							materials.</div
						>
					</div>
					<div class="pd_partner_img">
						<div class="pd_partner_sub_img">
							<img
								src="https://zy.metaera.media/assets/images/event/20.png"
								alt=""
							/>
						</div>
						<div class="pd_partner_sub_num">Exhibition Space</div>
						<div class="pd_partner_sub_name"
							>Showcase your project to attendees.</div
						>
					</div>
					<div class="pd_partner_img">
						<div class="pd_partner_sub_img">
							<img
								src="https://zy.metaera.media/assets/images/event/20.png"
								alt=""
							/>
						</div>
						<div class="pd_partner_sub_num">VIP Networking</div>
						<div class="pd_partner_sub_name"
							>Connect with industry professionals for potential
							collaborations.</div
						>
					</div>
				</div>
				<div class="pd_partner_content">
					<div class="pd_partner_img">
						<div class="pd_partner_sub_img">
							<img
								src="https://zy.metaera.media/assets/images/event/20.png"
								alt=""
							/>
						</div>
						<div class="pd_partner_sub_num">Media Coverage</div>
						<div class="pd_partner_sub_name"
							>Comprehensive media support from MetaEra.</div
						>
					</div>
					<div class="pd_partner_img">
						<div class="pd_partner_sub_img">
							<img
								src="https://zy.metaera.media/assets/images/event/20.png"
								alt=""
							/>
						</div>
						<div class="pd_partner_sub_num"> Exclusive Insight</div>
						<div class="pd_partner_sub_name"
							>Share the forward-thinking insights of Dr. Gavin Wood.
						</div>
					</div>
				</div>
			</div>
			<div class="pd_partner_line1"></div>
			<div class="pd_sponsor">
				<div class="pd_partner_line"> </div>
				<div class="pd_partner_line_red">
					<div class="pd_partner_line_red_sub"> </div>
				</div>
				<div class="pd_sponsor_title"> SPONSOR </div>
				<div class="pd_sponsor_content">
					<img
						v-for="(item, index) in mediaArray1"
						:key="index"
						class="pd_partner_content_img"
						:src="item.pic"
						:style="{ height: item.height + 'px' }"
					/>
				</div>
				<div class="pd_sponsor_title" style="margin-top: 0px">
					MEDIA PARTNERS
				</div>
				<div class="pd_sponsor_content">
					<img
						v-for="(item, index) in mediaArray2"
						:key="index"
						class="pd_partner_content_img pointer"
						:src="item.pic"
						:style="{ height: item.height + 'px' }"
						@click="showUrl(item.link)"
					/>
				</div>
				<div class="pd_sponsor_title"> COMMUNITY PARTNERS </div>
				<div class="pd_sponsor_content">
					<img
						v-for="(item, index) in mediaArray3"
						:key="index"
						class="pd_partner_content_img"
						:src="item.pic"
						:style="{ height: item.height + 'px' }"
					/>
				</div>
			</div>
			<div class="pd_partner_line1"></div>
			<bottom></bottom>
			<div class="demo" style="height: 50px"></div>
		</div>
	</div>
</template>

<script>
import headerNav from "./headern.vue";
import bottom from "./pdbottom.vue";
import pdintroduceVue from "./pdintroduce.vue";
import people from "./pdpeople.vue";

export default {
	name: "",
	components: {
		headerNav,
		bottom,
		people,
		pdintroduceVue,
	},
	data() {
		return {
			osafai: false,
			olead: false,
			isScrolled: true,
			currentPath: "/polkadot2024_partner",
			currentImage: "https://zy.metaera.media/assets/images/event/47.png", // 初始图片
			images: {
				a: "https://zy.metaera.media/assets/images/event/47.png", // 图片A
				b: "https://zy.metaera.media/assets/images/event/471.png", // 图片B
			},
			mediaArray1: [
				{
					pic: "https://zy.metaera.media/assets/images/event/lockup_GoogleCloud_FullColor_rgb_544x96px.png",
					link: "",
					height: 40,
				},
			],
			mediaArray2: [
				{
					pic: "https://zy.metaera.media/assets/images/event/1-ForesightNews@3x.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/2-金色财经-Logo-White@3x.png",
					link: "",
					height: 36,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/3-PaNews@3x.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/4-DeThings@3x.png",
					link: "",
					height: 36,
				},

				{
					pic: "https://zy.metaera.media/assets/images/event/20240826-111435.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/23-ZEX PR Wire Icon - Colored.png",
					link: " http://www.zexprwire.com/",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/5-Cointime@3x.png",
					link: "",
					height: 36,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/6-BOTD@3x.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/7-logo-黑@3x.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/8-LOGO-英文黑@3.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/9-快链头条Chain Time@3x.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/10-火讯财经-1@3x.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/11-WikiBit@3x.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/12-世链财经@3x.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/13-MarsBit@3x.png",
					link: "",
					height: 56,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/14-Followin svg@3x.png",
					link: "",
					height: 30,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/15-链得得ChainDD logo-2@3x1.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/16-Bitkoala@3x.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/17-ByToken@3x.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/18-BroadChain博链财经@3x.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/19-KnowHere知媒@3x.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/20-MetaSpace@3x.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/24-cryptoken.png",
					link: "https://cryptoken.media",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/25-ICOHOLDER.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/crypto.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/TechFlame Logo-Black-h.png",
					link: "",
					height: 80,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/ea8c14f6-6256-464a-af10-c6078dfb1b68.png",
					link: "",
					height: 40,
				},
			],
			mediaArray3: [
				{
					pic: "https://zy.metaera.media/assets/images/event/1-OneBlock+@3x1.png",
					link: "",
					height: 36,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/3-pwlogo@3x1.png",
					link: "",
					height: 36,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/2-aiweb33@3x.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/4-OG@3x1.png",
					link: "",
					height: 36,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/5-DoTphin@3x.png",
					link: "",
					height: 36,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/6-polkadot-insider-1.png",
					link: "",
					height: 76,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/13-MoonGate.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/blockbridgedigital.png",
					link: " http://www.zexprwire.com/",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/starlabs.png",
					link: " http://www.zexprwire.com/",
					height: 60,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/21-fantv.png",
					link: "",
					height: 50,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/20240826-112927.png",
					link: "",
					height: 46,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/2-Moledao@3x.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/3-NodeLab@3x.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/4-AvatarDAO@3x.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/5-巨鲸研究院@3x.png",
					link: "",
					height: 50,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/6-WIS@3x.png",
					link: "",
					height: 40,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/7-元宇宙文化产业@3x.png",
					link: "",
					height: 36,
				},
				{
					pic: "https://zy.metaera.media/assets/images/event/MetaEvent-black-02.png",
					link: "",
					height: 60,
				},
			],
		};
	},
	created() {},
	methods: {
		handleScroll(event) {
			this.isScrolled = true;
		},
		sendEmail() {
			const email = "event@metaera.hk";
			const subject = "";
			const body = "";
			window.location.href = `mailto:${email}?subject=${encodeURIComponent(
				subject
			)}&body=${encodeURIComponent(body)}`;
		},
		changeImage(imageKey) {
			this.currentImage = this.images[imageKey];
		},
		showUrl(url) {
			if (url == undefined || url.length == 0) {
				return;
			}
			window.open(url, "");
		},
	},
};
</script>
<style scoped>
.pd_body {
	height: 100vh;
	overflow-y: scroll;
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;

	background: white;
}
</style>

<!-- banner -->
<style scoped>
.pd_banner {
	margin-top: 80px;
	width: 100vw;
	height: calc(100vw * (1448 / 2560));
}

.pd_banner_img {
	width: 100%;
	height: auto;
}

.pd_banner_img_1 {
	display: flex;
}

.pd_banner_img_2 {
	display: none;
}
.pd_banner_img img {
	width: 100%;
	height: 100%;
}
.pd_banner_bottom {
	position: absolute;
	margin-left: 5%;
	margin-right: 5%;
	margin-top: -40px;
	height: 40px;
	width: 90%;
	background: white;
	border-top-right-radius: 30px;
	border-top-left-radius: 30px;
	z-index: 10;
	display: none;
}

.pd_banner_30 {
	position: absolute;
	left: calc(100vw * 90 / 1280);
	right: calc(100vw * 90 / 1280);
	top: calc(100vw * 220 / 1280);
	font-family: DINPro-Bold;
	font-size: calc(100vw * 100 / 1280);
	font-weight: bold;
	line-height: normal;
	letter-spacing: 0em;
	align-content: center;
	text-align: center;
	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #ffffff;
}

.pd_banner_31 {
	position: absolute;
	left: calc(100vw * 190 / 1280);
	right: calc(100vw * 190 / 1280);
	top: calc(100vw * 380 / 1280);
}

.pd_banner_31,
.pd_banner_31 span {
	font-family: D-DIN;
	font-size: calc(100vw * 20 / 1280);
	text-align: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
.pd_banner_31 span {
	text-decoration: underline;
}
.pd_banner_32 {
	position: absolute;
	left: calc(100vw * 565 / 1280);
	right: calc(100vw * 565 / 1280);
	top: calc(100vw * 580 / 1280);
	width: calc(100vw * 150 / 1280);
	height: calc(100vw * 44 / 1280);

	font-family: D-DIN;
	font-size: calc(100vw * 20 / 1280);
	text-align: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
.pd_banner_32 img {
	width: 100%;
	height: auto;
}
.pd_banner_img_overlay {
	position: absolute;
	top: 80px;
	left: 0;
	width: 100%;
	height: calc(100vw * (1448 / 2560));
	opacity: 0.8;
	background: black;
}
@media screen and (max-width: 900px) {
	.pd_banner_img_overlay {
		position: absolute;
		top: 50px;
		left: 0;
		width: 100%;
		height: calc(100vw * 750 / 800);
		opacity: 0.8;
		background: black;
	}
	.pd_banner {
		margin-top: 50px;
		height: calc(100vw * 750 / 800);
	}
	.pd_banner_img {
		width: 100%;
		height: 100%;
	}

	.pd_banner_img_1 {
		display: none;
	}

	.pd_banner_img_2 {
		display: flex;
	}
	.pd_banner_bottom {
		display: flex;
	}

	.pd_banner_30 {
		position: absolute;
		left: calc(100vw * 50 / 375);
		right: calc(100vw * 50 / 375);
		top: calc(100vw * 80 / 375);
		font-family: DINPro-Bold;
		font-size: calc(100vw * 20 / 375);
		font-weight: bold;
		line-height: normal;
		letter-spacing: 0em;
		text-align: center;
		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #ffffff;
	}

	.pd_banner_31 {
		position: absolute;
		left: calc(100vw * 30 / 375);
		right: calc(100vw * 30 / 375);
		top: calc(100vw * 120 / 375);
	}

	.pd_banner_31,
	.pd_banner_31 span {
		font-family: D-DIN;
		font-size: calc(100vw * 12 / 375);
		text-align: center;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		color: #ffffff;
	}

	.pd_banner_31 span {
		text-decoration: underline;
	}

	.pd_banner_32 {
		position: absolute;
		left: calc(100vw * 125 / 375);
		right: calc(100vw * 125 / 375);
		top: calc(100vw * 280 / 375);
		width: calc(100vw * 121 / 375);
		height: calc(100vw * 36 / 375);

		font-family: D-DIN;
		font-size: calc(100vw * 14 / 375);
		text-align: center;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		color: #ffffff;
	}
}
</style>
<!-- pd_partner -->
<style scoped>
.pd_partner {
	width: 100%;
	padding-top: 30px;
	background: white;
}
.pd_partner_line {
	margin-left: 150px;
	margin-right: 150px;
	height: 1px;
	opacity: 1;
	background: #e7e7e7;
}
.pd_partner_line_red {
	display: flex;
	justify-content: center; /* 水平居中 */
	align-items: center; /* 垂直居中，如果需要的话 */
}

.pd_partner_line_red_sub {
	margin-top: -1px;
	width: 80px;
	height: 2px;
	opacity: 1;
	background: #e6007a;
}

.pd_partner_title {
	margin-top: 43px;
	margin-left: 120px;
	margin-right: 120px;
	font-family: DINPro-Bold;
	font-size: 50px;
	font-weight: bold;
	line-height: normal;
	text-align: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
}
.pd_partner_subtitle {
	margin-top: 30px;
	font-family: DINPro-Regular, DINPro;
	font-size: 24px;
	font-weight: normal;
	line-height: 30px;
	align-content: center;
	align-items: center;
	text-align: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
}
.pd_partner_content {
	width: 80%;
	margin-top: 20px;
	margin-left: 10%;
	margin-right: 10%;
	padding-top: 50px;
	display: flex;
	justify-content: space-between;
	align-content: center;
	text-align: center;
}
.pd_partner_img {
	width: 50%;
	height: auto;
	margin-right: 20px;
	margin-left: 20px;
}
.pd_partner_sub_img {
	width: 70px;
	height: 70px;
	display: inline-block;
	align-content: center;
	align-items: center;
	text-align: center;
}
.pd_partner_sub_num {
	margin-top: 30px;
	font-family: DINPro-Bold;
	font-size: 36px;
	font-weight: bold;
	line-height: normal;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
}

.pd_partner_sub_name {
	margin-top: 20px;
	font-family: D-DIN;
	font-size: 20px;
	font-weight: normal;
	line-height: 30px;
	text-align: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
}
.pd_partner_sub_img img {
	width: 100%;
	height: auto;
}

.pd_partner_line1 {
	/* width: 100%; */
	margin-top: 90px;
}
@media screen and (max-width: 900px) {
	.pd_partner_title {
		margin-top: 20px;
		margin-left: 20px;
		margin-right: 20px;
		font-family: DINPro-Bold;
		font-size: 24px;
	}
	.pd_partner_subtitle {
		margin-top: 20px;
		margin-left: 20px;
		margin-right: 20px;
		font-size: 18px;
	}

	.pd_partner_content {
		display: block;
		margin-left: 10%;
		margin-right: 10%;
		margin-top: 0px;
		padding-top: 0px;
	}

	.pd_partner_img {
		width: 100%;
		height: auto;
		margin-top: 50px;
		margin-right: 0px;
		margin-left: 0px;
	}
	.pd_partner_sub_img {
		width: 60px;
		height: 60px;
	}

	.pd_partner_sub_num {
		margin-top: 15px;
		font-size: 22px;
	}

	.pd_partner_sub_name {
		margin-top: 10px;
		font-size: 14px;
	}
}
</style>

<!-- pd_sponsor -->
<style scoped>
.pd_sponsor {
	width: 100%;
	margin-top: 30px;
	background: white;
}
.pd_sponsor_title {
	margin-top: 43px;
	margin-left: 120px;
	margin-right: 120px;
	font-family: DINPro-Bold;
	font-size: 60px;
	font-weight: bold;
	line-height: normal;
	text-align: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
}

.pd_sponsor_content {
	margin-left: 10%;
	margin-right: 10%;
	margin-top: 30px;
	margin-bottom: 70px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 50px; /* 设置图片间距 */
	padding: 10px; /* 容器内边距 */
}

.pd_partner_content_img {
	/* height: 40px;  */
	width: auto; /* 宽度自适应 */
	object-fit: cover; /* 保持图片的宽高比 */
}

@media screen and (max-width: 900px) {
	.pd_sponsor_title {
		margin-top: 43px;
		margin-left: 20px;
		margin-right: 20px;
		font-size: 60px;
	}
}
</style>
